import { FooterNav } from '../FooterNav';
import styles from './footer.module.css';
/**
 * component that creates the footer, the component consists of two parts the first is the footerNav component and the second is an Ul with contact information
 */
export function Footer() {
  return (
    <footer>
      <FooterNav></FooterNav>
      <ul className={styles.contactInfoUl}>
        <li>
          <h3>Kontakt oss</h3>
        </li>
        <li>
          Tlf: <a href="tel: +47 96505040">+47 96505040</a>
        </li>
        <li>
          Email:
          <a href="mailto: Verkshuset@outlook.com">verkshuset@outlook.com</a>
        </li>
      </ul>
    </footer>
  );
}
