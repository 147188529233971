import { Link } from 'react-router-dom';
import styles from './overnatting.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
/**
 * component that creates a section with information about renting rooms
 */
export function RentRoomSection() {
  return (
    <section className={styles.overnattingSection}>
      <div className={styles.imgCon}>
        <img
          src="/img/roomImg/StortDobbeltRom.jpg"
          alt="bilde av et av rommene"
        />
      </div>
      <div className={styles.textCon}>
        <h2>Overnatting</h2>
        <p>
          Vi tilbyr overnatting med selvbetjent frokost, vi har 14 rom og tilbyr
          både enkeltrom, dobbeltrom og familierom med plass til fem, vi har
          også et rom der det er mulighet for å ta med kjæledyr.
        </p>
        <div className={styles.link}>
          <Link to="/overnattinger">Les mer om overnattinger</Link>
          <FontAwesomeIcon icon={faArrowRight} className={styles.arrow} />
        </div>
      </div>
    </section>
  );
}
