import {
  faUtensils,
  faShower,
  faRestroom,
  faTv,
  faTemperatureLow,
  faPeopleArrows,
} from '@fortawesome/free-solid-svg-icons';
export const roomArray = [
  {
    name: 'Stort dobbeltrom',
    img: '/img/roomImg/stortDobbeltRom.jpg',
    included: [
      { title: 'Inkluderer selvbetjent frokost', icon: faUtensils },
      { title: 'Egen dusj', icon: faShower },
      { title: 'Eget toalett', icon: faRestroom },
      { title: 'Tv på rommet', icon: faTv },
      { title: 'Kjøleskap på rommet', icon: faTemperatureLow },
    ],
    price: 1390,
  },
  {
    name: 'Stort enkeltrom',
    img: '/img/roomImg/stortEnkeltR.jpg',
    included: [
      { title: 'Inkluderer selvbetjent frokost', icon: faUtensils },
      { title: 'Egen dusj', icon: faShower },
      { title: 'Eget toalett', icon: faRestroom },
      { title: 'Tv på rommet', icon: faTv },
      { title: 'Kjøleskap på rommet', icon: faTemperatureLow },
    ],
    price: 1090,
  },
  {
    name: 'Dobbeltrom',
    img: '/img/roomImg/liteDobbeltR.jpg',
    included: [
      { title: 'Inkluderer selvbetjent frokost', icon: faUtensils },
      { title: 'Felles dusj i gangen', icon: faShower },
      { title: 'Felles toalett i gangen', icon: faRestroom },
      { title: 'Tv i fellesrom', icon: faTv },
    ],
    price: 1190,
  },
  {
    name: 'Enkeltrom',
    img: '/img/roomImg/liteEnkeltR.jpg',
    included: [
      { title: 'Inkluderer selvbetjent frokost', icon: faUtensils },
      { title: 'Felles dusj i gangen', icon: faShower },
      { title: 'Felles toalett i gangen', icon: faRestroom },
      { title: 'Tv i fellesrom', icon: faTv },
    ],
    price: 890,
  },

  {
    name: 'Familierom',
    img: '/img/roomImg/familieRom.jpg',
    included: [
      { title: 'Inkluderer selvbetjent frokost', icon: faUtensils },
      { title: 'Egen dusj', icon: faShower },
      { title: 'Eget toalett', icon: faRestroom },
      { title: 'Tv på rommet', icon: faTv },
      { title: 'Kjøleskap på rommet', icon: faTemperatureLow },
      { title: 'inntil 5 personer', icon: faPeopleArrows },
    ],
    price: 1790,
  },
];
