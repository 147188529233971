import styles from './imageAndText.module.css';
/**
 * component that is used to create a section that consists of an image, a h2 and text, the component takes prop that is called "reversed" if reversed os true the image will display
 * on the opposite side
 * @param {props} props
 */
export function VenueImageAndTextSection(props) {
  const { image, altText, text, h2, reversed } = props;
  return (
    <section>
      <h2 className={styles.h2}>{h2}</h2>
      <div
        className={
          reversed === 'false'
            ? styles.imageAndTextCon
            : styles.imageAndTextConReversed
        }
      >
        <div className={styles.imageCon}>
          <img src={image} alt={altText} />
        </div>
        <div>
          <p>{text}</p>
        </div>
      </div>
    </section>
  );
}
