export const imageArray = [
  { url: '/img/utsiden.jpg', alt: 'bilde av lokalet' },
  { url: '/img/Gallery/stortDobbeltRomSenger.jpg', alt: 'bilde av Dobbelseng' },
  { url: '/img/Gallery/stortDobbeltRom.jpg', alt: 'bilde av dobbelt rom' },
  { url: '/img/Gallery/familieRom.jpg', alt: 'bilde av grupperom' },
  { url: '/img/Gallery/StortEnkeltR.jpg', alt: 'bilde av stort enkelt rom' },
  { url: '/img/Gallery/liteDobbeltR.jpg', alt: 'bilde av lite dobbeltrom' },
  { url: '/img/Gallery/liteEnkeltR.jpg', alt: 'bilde av lite enkeltrom' },
  { url: '/img/Gallery/bad.jpg', alt: 'bilde av bad' },
  { url: '/img/Gallery/dusj.jpg', alt: 'bilde av dusj' },
  { url: '/img/Gallery/fellesStue.jpg', alt: 'bilde av fellesstue' },
  { url: '/img/VenueImg/stortLokaletNytt.jpg', alt: 'bilde av stort lokalet' },
  {
    url: '/img/VenueImg/stortLokaletBak.jpg',
    alt: 'bilde av stort lokalet',
  },
  { url: '/img/VenueImg/scene.jpg', alt: 'bilde av scene' },
  { url: '/img/VenueImg/lokaletScene.jpg', alt: 'bilde av lokalet og scene' },
  { url: '/img/VenueImg/bar.jpg', alt: 'bilde av bar' },
  { url: '/img/Gallery/lokaletStoler.jpg', alt: 'bilde av lite lokale' },
  {
    url: '/img/Gallery/fotoAvVerket.jpg',
    alt: 'bilde av et bilde av frolands verk',
  },
  { url: '/img/Gallery/gang.jpg', alt: 'bilde av gang' },
  { url: '/img/Gallery/hovedDør.jpg', alt: 'bilde av hoved døra' },
  { url: '/img/Gallery/kjeller.jpg', alt: 'bilde av kjeller' },
  { url: '/img/Gallery/spisesal.jpg', alt: 'bilde av spisesal' },
  { url: '/img/Gallery/kjøleskap.jpg', alt: 'bilde av kjøleskap' },
  { url: '/img/Gallery/resepsjon.jpg', alt: 'bilde av resepsjon' },
  { url: '/img/Gallery/vask.jpg', alt: 'bilde av møte vask' },
];
