import styles from './contactForm.module.css';
import { useForm } from 'react-hook-form';
import { emailValidation } from '../../js/yupShema';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { sendEmail } from '../../js/sendEmail';
export function ContactForm() {
  const [msgSent, setMsgSent] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [h2Content, setH2Content] = useState('Kontakt skjema');
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(emailValidation) });
  const onSubmit = async (data) => {
    setLoading(true);
    reset();
    const response = await sendEmail(data, setError);
    if (loading === false) {
      if (response.success) {
        setMsgSent(true);
        setTimeout(() => setMsgSent(false), 10000);
      } else {
        setH2Content(
          'Beklager noe gikk galt med insending av skjema vennligst prøv igjen senere eller kontakt oss på epost eller mobil'
        );
      }
    }
    setLoading(false);
  };
  const onXClick = () => {
    setMsgSent(false);
  };
  if (loading === false) {
    return (
      <div className={styles.formCon}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={msgSent ? styles.overlay : styles.overlayHidden}>
            <div className={styles.xCon}>
              <FontAwesomeIcon
                icon={faX}
                className={styles.x}
                onClick={onXClick}
              />
            </div>
            <div>
              <h2>Takk for din melding!</h2>
              <p>
                Tusen takk for din melding vi vil svare så fort mi kan på epost
              </p>
            </div>
            <div className={styles.loadingBar}></div>
          </div>
          <h2 className={error ? styles.errorh2 : styles.h2KontaktSkjema}>
            {h2Content}
          </h2>
          <div className={styles.titleAndInput}>
            <label
              htmlFor="name"
              className={!errors.name ? styles.label : styles.errorMsg}
            >
              {!errors.name ? 'Navn' : errors.name.message}
            </label>
            <input
              type="text"
              name="name"
              disabled={msgSent}
              id="name"
              className={!errors.name ? styles.textInput : styles.textInputErr}
              {...register('name')}
            />
          </div>
          <div className={styles.titleAndInput}>
            <label
              htmlFor="email"
              className={!errors.email ? styles.label : styles.errorMsg}
            >
              {!errors.email ? 'Epost' : errors.email.message}
            </label>
            <input
              type="text"
              name="email"
              disabled={msgSent}
              id="email"
              className={!errors.email ? styles.textInput : styles.textInputErr}
              {...register('email')}
            />
          </div>
          <div className={styles.titleAndInput}>
            <label
              htmlFor="subject"
              className={!errors.subject ? styles.label : styles.errorMsg}
            >
              {!errors.subject ? 'Emne' : errors.subject.message}
            </label>
            <input
              type="text"
              name="subject"
              disabled={msgSent}
              id="subject"
              {...register('subject')}
              className={
                !errors.subject ? styles.textInput : styles.textInputErr
              }
            />
          </div>
          <div className={styles.titleAndInput}>
            <label
              htmlFor="message"
              className={!errors.message ? styles.label : styles.errorMsg}
            >
              {!errors.message ? 'Melding' : errors.message.message}
            </label>
            <textarea
              id="message"
              {...register('message')}
              name="message"
              disabled={msgSent}
              rows="8"
              cols="50"
              className={
                !errors.message
                  ? styles.textFieldInput
                  : styles.textFieldInputErr
              }
            ></textarea>
          </div>
          <div className={styles.titleAndInput}>
            <input
              type="submit"
              name="submit"
              id="submit"
              value="Send"
              className={styles.submit}
              disabled={msgSent}
            />
          </div>
        </form>
      </div>
    );
  } else {
    return (
      <div className={styles.spinnerCon}>
        <div className={styles.spinner}></div>
      </div>
    );
  }
}
