import { Link } from 'react-router-dom';
import styles from './bookBtn.module.css';
export function BookBtn() {
  return (
    <Link
      to="https://picassoonline.techotel.dk/no/vaerkshuset-froland/vaerkshuset-froland/booking/rooms/"
      className={styles.bookBtn}
    >
      Book her
    </Link>
  );
}
