import { createGlobalStyle } from 'styled-components';
import '../../src/css/fonts.css';
const CustomGlobalStyles = createGlobalStyle`
main{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 114px;
}
    h1,h2,h3,h4,h5,a,button{
   font-family: PlayfairDisplay;
    }
   h1{
       font-size:2em;}
   p{
    font-family: SourceSans;
   }
body{
margin: 0;
}
@media (min-width:950px ){
main{
    padding-top: 172.5px;
}
}
`;
export default CustomGlobalStyles;
