import { Link } from 'react-router-dom';
import styles from './nav.module.css';
/**
 * component that creates the navigation
 * @param {props} props
 */
export function Nav(props) {
  const { active } = props;
  return (
    <div className={active ? styles.navCon : styles.navHidden}>
      <ul className={styles.navUl}>
        <li>
          <Link to="/">Hjem</Link>
        </li>
        <li>
          <Link to="/galleri">Galleri</Link>
        </li>
        <li>
          <Link to="/lokaler">Lokaler</Link>
        </li>
        <li>
          <Link to="/overnattinger">Overnattinger</Link>
        </li>
        <li>
          <Link to="/kontaktOss">Kontakt oss</Link>
        </li>
        <li>
          <Link to="/omOss">Om oss</Link>
        </li>
      </ul>
    </div>
  );
}
