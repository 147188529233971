/**
 * function that uses emailJs to send emails from contact form
 */
import emailjs from '@emailjs/browser';
export async function sendEmail(data, setError) {
  try {
    await emailjs.send('service_6z82swc', 'template_b1qoxvo', data, {
      publicKey: 'Yms-ndI8zal7-Thf_',
    });
    setError(false);
    return { success: true };
  } catch (error) {
    setError(true);
    return { success: false };
  }
}
