import styles from './contact.module.css';
/**
 * component that creates the contact information used on the contact page, this include a heading, text, email and phone number
 */
export function ContactPageContactInfo() {
  return (
    <section className={styles.contactSection}>
      <h1 className={styles.h1}>Kontakt oss</h1>
      <p className={styles.contactInfoText}>
        Om du har noen spørsmål enten om overnatting, utleie av lokale eller
        annet, så ta gjerne kontakt på telefon, email, eller bruk kontakt skjema
        under, så svarer vi så fort vi kan.
      </p>
      <div className={styles.NrOrEmailCon}>
        <p>Telefon:</p>
        <a href="tel: +47 96505040">+47 96505040</a>
      </div>
      <div className={styles.NrOrEmailCon}>
        <p>Epost:</p>
        <a href="mailto: Verkshuset@outlook.com">verkshuset@outlook.com</a>
      </div>
    </section>
  );
}
