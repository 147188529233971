import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './roomCards.module.css';
/**
 * component that creates the room cards, the function takes one prop, room array which is maped to create the room cards.
 * @param {props} props
 */
export function RoomCard(props) {
  const { roomArray } = props;
  return (
    <section>
      {roomArray.map((obj, indx) => {
        const metaArray = obj.included;
        return (
          <div key={indx} className={styles.roomCard}>
            <div className={styles.imgCon}>
              <img src={obj.img} alt="room" />
            </div>
            <div className={styles.infoCon}>
              <h2>{obj.name}</h2>
              <div className={styles.metaCon}>
                {metaArray.map((metaObj, index) => (
                  <div key={index} className={styles.metaTitleAndIcon}>
                    <FontAwesomeIcon
                      icon={metaObj.icon}
                      className={styles.icon}
                    />
                    <p>{metaObj.title}</p>
                  </div>
                ))}
              </div>
              <div className={styles.priceAndLink}>
                <p className={styles.price}>{obj.price}kr per natt</p>
                <Link
                  to="https://picassoonline.techotel.dk/no/vaerkshuset-froland/vaerkshuset-froland/booking/rooms/"
                  className={styles.bookLink}
                >
                  Bestill rom her
                </Link>
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
}
