import { RentVenueContactInfo } from '../RentVenueContactInfo';
import styles from './contactSection.module.css';
/**
 * component that creates the contact section for the rent venue page
 */
export function RentVenueContactSection() {
  return (
    <section className={styles.contactSection}>
      <h2>Gode pakkedordninger</h2>
      <p>
        Vi tilbyr gode pakkeløsningen uansett hva slags lokale og arrangement du
        måte ønske.
      </p>
      <p>
        Om du har noe spørsmål om lokalene eller pris, eller ønsker å leie
        lokale hos oss så gjerne ta kontakt på mobil, Epost eller vårt kontakt
        skjema
      </p>
      <RentVenueContactInfo></RentVenueContactInfo>
    </section>
  );
}
