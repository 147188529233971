import styles from './rentRoom.module.css';
import { RoomCard } from '../../components/RoomCards';
import { roomArray } from '../../js/roomTypes';
import { Helmet, HelmetProvider } from 'react-helmet-async';
/**
 * component that creates the rent a room page, the compoent uses react helemet for SEO
 */
export function RentRoomPage() {
  return (
    <main>
      <HelmetProvider>
        <Helmet>
          <title>VærksHuset | Rom utleie</title>
          <meta
            name="description"
            content="Utforsk våre komfortable rom for utleie på Kleivene i Froland. Perfekt for korte og lange opphold, med flotte fasiliteter og vakre omgivelser. Bestill ditt rom i dag"
          />
          <meta
            name="keywords"
            content="romutleie, Kleivene, Froland,Agder, overnatting, komfortable rom, korte opphold, lange opphold, moderne fasiliteter, flotte fasiliteter, naturskjønne omgivelser, bestill rom, bed and breakfast, hotellrom, ferie"
          />
        </Helmet>
      </HelmetProvider>
      <h1 className={styles.h1}>Overnatting</h1>
      <RoomCard roomArray={roomArray}></RoomCard>
    </main>
  );
}
