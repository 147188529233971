import { Helmet, HelmetProvider } from 'react-helmet-async';
import { GalleryContent } from '../../components/GalleryContent.jsx';
import { imageArray } from '../../js/imageArray.js';
/**
 * component that creates the rent a gallery page, the compoent uses react helemet for SEO
 */
export function GalleryPage() {
  return (
    <main>
      <HelmetProvider>
        <Helmet>
          <title>VærksHuset | Galleri</title>
          <meta
            name="description"
            content="Se vårt galleri med bilder fra Kleivene i Froland. Utforsk våre vakre rom, moderne fasiliteter, og naturskjønne omgivelser. Få et innblikk i hva du kan forvente under ditt opphold."
          />
          <meta
            name="keywords"
            content="galleri, bilder, Kleivene, Froland, rom, fasiliteter, naturskjønne omgivelser, overnatting, bed and breakfast, romutleie, lokaleutleie, innkvartering, visuell tur, fotogalleri, fotoalbum, opphold"
          />
        </Helmet>
      </HelmetProvider>
      <GalleryContent imageArray={imageArray}></GalleryContent>
    </main>
  );
}
