import { Link } from 'react-router-dom';
import styles from './contactInfo.module.css';
/**
 * component that creates the contact information section for the rent venue page
 */
export function RentVenueContactInfo() {
  return (
    <div className={styles.contactInfoCon}>
      <h3>Kontakt informasjon</h3>
      <div className={styles.NrOrEmailCon}>
        <p>Telefon:</p>
        <a href="tel: +47 96505040">+47 96505040</a>
      </div>
      <div className={styles.NrOrEmailCon}>
        <p>Epost:</p>
        <a href="mailto: verkshuset@outlook.com">verkshuset@outlook.com</a>
      </div>
      <Link to="/kontaktOss" className={styles.link}>
        Kontakt skjema
      </Link>
    </div>
  );
}
