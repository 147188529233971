import { Link } from 'react-router-dom';
import styles from './location.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
/**
 * component that creates location section for the landing page, the section includes a ifram from google maps
 */
export function LocationSection() {
  return (
    <section className={styles.locationSection}>
      <div>
        <div className={styles.map}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1239.580464622088!2d8.599185704936502!3d58.49927253422051!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4647927cedcc68f3%3A0x27990da2e23a219d!2sEikely%20Leirsted!5e0!3m2!1sno!2sno!4v1719327028895!5m2!1sno!2sno"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="maps"
          ></iframe>
        </div>
      </div>
      <div className={styles.textCon}>
        <h2>Sted</h2>
        <p>
          Værkshuset ligger mellom Osedalen og Frolands verk. Det er god
          bussforbindelse til Arendal. Det er også bussforbindelse til Hovden.
          Blakstad togstasjon er 10 minutter unna. Adresse: Kleivene 6, 4827
          Froland
        </p>
        <div className={styles.mapLinkCon}>
          <Link to="https://www.google.com/maps/dir//Kleivene+6,+4827+Frolands+Verk">
            Veibeskrivelse
          </Link>
          <FontAwesomeIcon icon={faArrowRight} className={styles.arrow} />
        </div>
      </div>
    </section>
  );
}
