import { Helmet, HelmetProvider } from 'react-helmet-async';
import { AboutUsSection } from '../../components/AboutUsSection';
/**
 * component that creates the about us page, the compoent uses react helemet for SEO
 */
export function AboutUsPage() {
  return (
    <main>
      <HelmetProvider>
        <Helmet>
          <title>VærksHuset | Om oss</title>
          <meta
            name="description"
            content="Lær mer om vår overnattingsplass på Kleivene i Froland.
             Vi tilbyr bed and breakfast, romutleie og lokaler for alle anledninger. Oppdag vår historie, verdier og engasjement for gjestetilfredshet."
          />
          <meta
            name="keywords"
            content="om oss, Kleivene, Froland, bed and breakfast, romutleie, lokaleutleie, overnattingsplass, historie, verdier, gjestetilfredshet, vår historie, vår visjon, vår misjon, engasjement, selskapshistorie"
          />
        </Helmet>
      </HelmetProvider>
      <AboutUsSection></AboutUsSection>
    </main>
  );
}
