import { Helmet, HelmetProvider } from 'react-helmet-async';
import { HeroSection } from '../../components/HeroSection';
import { LocationSection } from '../../components/LocationSection';
import { RentRoomSection } from '../../components/RentRoomSection';
import { VenueSection } from '../../components/VenueSection';
import { BookingSection } from '../../components/BookingSection';
/**
 * component that creates the landing page, the compoent uses react helemet for SEO
 */
export function LandingPage() {
  return (
    <main>
      <HelmetProvider>
        <Helmet>
          <title>VærksHuset | Hjem</title>
          <meta
            name="description"
            content="Opplev komfortabel overnatting på Kleivene i Froland med vår bed and breakfast.
             Vi tilbyr romutleie og utleie av lokaler for alle anledninger. Perfekt for avslappende opphold og arrangementer i naturskjønne omgivelser til gode priser."
          />
          <meta
            name="keywords"
            content="overnatting, bed and breakfast, romutleie, lokaleutleie, Kleivene, Froland, Agder, overnattingsplass, arrangementer, naturskjønne omgivelser, ferie, komfortabel overnatting, hotell, eventlokaler, konferanserom, utleie, gode priser, billig overnatting"
          />
        </Helmet>
      </HelmetProvider>
      <HeroSection></HeroSection>
      <BookingSection></BookingSection>
      <RentRoomSection></RentRoomSection>
      <VenueSection></VenueSection>
      <LocationSection></LocationSection>
    </main>
  );
}
