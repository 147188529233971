import { useState } from 'react';
import styles from './gallery.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faX,
  faArrowLeft,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
/**
 * component that creates the content for the gallery page, this includes the gallery and images and an overlay for when users interact with an image.
 */
export function GalleryContent(props) {
  const { imageArray } = props;
  const [imageIndex, setImageIndex] = useState(null);
  function handleClick(index) {
    setImageIndex(index);
  }
  function closeOverlay() {
    setImageIndex(null);
  }
  const showNextImage = (e) => {
    e.stopPropagation();
    setImageIndex((prevIndex) => (prevIndex + 1) % imageArray.length);
  };

  const showPrevImage = (e) => {
    e.stopPropagation();
    setImageIndex(
      (prevIndex) => (prevIndex - 1 + imageArray.length) % imageArray.length
    );
  };
  const selectedImage = imageIndex !== null ? imageArray[imageIndex] : null;
  return (
    <section className={styles.gallerySection}>
      <h1>Galleri</h1>
      <div className={styles.arrayWrapper}>
        <div className={styles.arrayCon}>
          {imageArray.map((obj, idx) => {
            return (
              <div key={idx}>
                <img
                  src={obj.url}
                  alt={obj.alt}
                  onClick={() => {
                    handleClick(idx);
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      {selectedImage && (
        <div className={styles.imageOverlay}>
          <div className={styles.imageOverlayContent}>
            <div className={styles.arrowCon}>
              <FontAwesomeIcon
                icon={faArrowLeft}
                className={styles.arrow}
                onClick={showPrevImage}
              />
              <FontAwesomeIcon
                icon={faArrowRight}
                className={styles.arrow}
                onClick={showNextImage}
              />
            </div>
            <div className={styles.imageXWrapper}>
              <FontAwesomeIcon
                role="button"
                type="button"
                icon={faX}
                onClick={closeOverlay}
                className={styles.imageX}
              />
            </div>
            <img
              src={imageArray[imageIndex].url}
              alt={imageArray[imageIndex].alt}
            />
          </div>
        </div>
      )}
    </section>
  );
}
