import { Link } from 'react-router-dom';
import styles from './footerNav.module.css';
/**
 * component that creates the navigation in the footer, the navigation uses and unordered list to contain Links.
 */
export function FooterNav() {
  return (
    <div className={styles.footerNavCon}>
      <ul className={styles.FooterNavUl}>
        <li>
          <h3>Snarveier</h3>
        </li>
        <li>
          <Link to="/">Hjem</Link>
        </li>
        <li>
          <Link to="/galleri">Galleri</Link>
        </li>
        <li>
          <Link to="/lokaler">Lokaler</Link>
        </li>
        <li>
          <Link to="/overnattinger">Overnattinger</Link>
        </li>
        <li>
          <Link to="/kontaktOss">Kontakt oss</Link>
        </li>
        <li>
          <Link to="/omOss">Om oss</Link>
        </li>
      </ul>
    </div>
  );
}
