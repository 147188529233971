import styles from './contact.module.css';
import { Link } from 'react-router-dom';
/**
 * component that displays the contact information and location information used on the about us page.
 */
export function AboutUsContactInfo() {
  return (
    <div>
      <div className={styles.contactInfoCon}>
        <h3>Sted og Kontakt informasjon</h3>
        <div className={styles.NrOrEmailCon}>
          <p>Telefon:</p>
          <a href="tel: +47 96505040">+47 96505040</a>
        </div>
        <div className={styles.NrOrEmailCon}>
          <p>Epost:</p>
          <a href="mailto: Verkshuset@outlook.com">verkshuset@outlook.com</a>
        </div>
        <Link to="/kontaktOss" className={styles.link}>
          Kontakt skjema
        </Link>
      </div>
      <div>
        <div className={styles.map}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1239.580464622088!2d8.599185704936502!3d58.49927253422051!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4647927cedcc68f3%3A0x27990da2e23a219d!2sEikely%20Leirsted!5e0!3m2!1sno!2sno!4v1719327028895!5m2!1sno!2sno"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="maps"
          ></iframe>
        </div>
      </div>
      <div className={styles.textCon}>
        <p>
          Værkshuset er plassert i mellom Osedalen og Frolands verk og ligger på
          kleivene, adresse er kleivene 6, Froland
        </p>
        <div className={styles.mapLinkCon}>
          <Link
            to="https://www.google.com/maps/dir//Kleivene+6,+4827+Frolands+Verk"
            className={styles.mapLink}
          >
            Veibeskrivelse
          </Link>
        </div>
      </div>
    </div>
  );
}
