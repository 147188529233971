import styles from './heroSection.module.css';
/**
 * component that creates the hero section for the landing page, this includes an image, a heading and a text
 */
export function HeroSection() {
  return (
    <section className={styles.heroSection}>
      <div className={styles.imgCon}>
        <img src="/img/utsiden.jpg" alt="bilde av bygget" />
      </div>
      <div className={styles.welcomeMsgCon}>
        <h1>Velkommen til Værkshuset</h1>
        <p>
          Bed and breakfast, overnattinger og utleie av lokaler til kurs,
          konferanser og selskaper sentralt på Frolands verk i Froland
        </p>
      </div>
    </section>
  );
}
