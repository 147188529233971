import * as Yup from 'yup';
/**
 * schema from yup used as validation for the contact form
 */
export const emailValidation = Yup.object({
  name: Yup.string()
    .required('Navn må fylles inn')
    .matches(
      /^[A-Za-zÆØÅæøå\s]+$/,
      'Navn kan ikke inneholde tall eller spesialtegn'
    )
    .min(2, 'Navn må være minst to bokstaver'),
  email: Yup.string()
    .email('Venligst bruk en gyldig epost adresse')
    .required('Email må fylles inn'),
  message: Yup.string()
    .required('Melding må fylles inn')
    .min(10, 'Meldingen må være minst ti bokstaver lang'),
  subject: Yup.string()
    .required('Emne må fylles inn')
    .min(2, 'Emne må være minst to bokstaver'),
});
