import { Nav } from '../Nav';
import styles from './header.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faX } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
/**
 * component that creates the header, calls the Nav component and uses a onclick function to toogle the hamburger menu
 */
export function Header() {
  const [activeMobileNav, setActiveMobileNav] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const UserScrolled = window.scrollY > 50;
      setScrolled(UserScrolled);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  function onClick() {
    setActiveMobileNav(!activeMobileNav);
  }
  return (
    <header className={scrolled ? styles.headerSmall : styles.header}>
      <div
        className={
          scrolled
            ? styles.hamburgerAndLogoConSmall
            : styles.hamburgerAndLogoCon
        }
      >
        <div className={scrolled ? styles.logoConSmall : styles.logoCon}>
          <Link to="/">
            <img src="/img/værkshuset.png" alt="værks huset logo" />
          </Link>
        </div>
        <div className={styles.hamburgerCon}>
          <FontAwesomeIcon
            icon={activeMobileNav ? faX : faBars}
            className={styles.hamburger}
            onClick={onClick}
          />
        </div>
      </div>
      <Nav active={activeMobileNav}></Nav>
    </header>
  );
}
