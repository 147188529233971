import styles from './bookingSection.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { BookBtn } from '../BookBtn';
/**
 * component that creates a section with information about renting venues
 */
export function BookingSection() {
  return (
    <section className={styles.bookingSection}>
      <div className={styles.imgCon}>
        <img src="/img/Gallery/resepsjon.jpg" alt="bilde av resepsjonen" />
      </div>
      <div className={styles.textCon}>
        <h2>Booking</h2>
        <p>
          Vi er et nyåpnet gjestgiveri som nå er klare for å ta imot
          bestillinger. Booking er mulig både via online booking og ved å
          kontakte oss direkte. Online booking er mulig fra fredag 19. juli.
          Bestillinger før dette gjøres ved å kontakte oss direkte.
        </p>
        <div className={styles.link}>
          <Link to="/kontaktOss">Kontakt informasjon</Link>
          <FontAwesomeIcon icon={faArrowRight} className={styles.arrow} />
        </div>
        <BookBtn></BookBtn>
      </div>
    </section>
  );
}
