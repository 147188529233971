import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ContactPageContactInfo } from '../../components/ContactPageContactInfo';
import { ContactForm } from '../../components/ContactForm';
/**
 * component that creates the rent a contact page, the compoent uses react helemet for SEO
 */
export function ContactPage() {
  return (
    <main>
      <HelmetProvider>
        <Helmet>
          <title>VærksHuset | Kontakt oss</title>
          <meta
            name="description"
            content="Kontakt oss på Kleivene i Froland for spørsmål om vår bed and breakfast, romutleie og lokaleutleie. Vi er her for å hjelpe deg med planleggingen av ditt opphold eller arrangement."
          />
          <meta
            name="keywords"
            content="kontakt oss, Kleivene, Froland, Agder, bed and breakfast, romutleie, lokaleutleie, overnattingsplass, spørsmål, planlegging, opphold, arrangement, kundeservice, kontaktinformasjon, booking, henvendelser"
          />
        </Helmet>
      </HelmetProvider>
      <ContactPageContactInfo></ContactPageContactInfo>
      <ContactForm></ContactForm>
    </main>
  );
}
