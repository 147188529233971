import styles from './utleieSection.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
/**
 * component that creates a section with information about renting venues
 */
export function VenueSection() {
  return (
    <section className={styles.lokalerSection}>
      <div className={styles.imgCon}>
        <img src="/img/lokaler.jpg" alt="bilde av et av rommene" />
      </div>
      <div className={styles.textCon}>
        <h2>Utleie av lokaler</h2>
        <p>
          Vi tilbyr utleie av lokaler, til selskaper, konfirmasjoner, bryllup og
          minnestunder. Vi har et stort lokale med plass til 120 gjester og et
          med plass til 40 gjester. Servering kan også ordnes etter avtale.
        </p>
        <div className={styles.link}>
          <Link to="/lokaler">Les mer om utleie</Link>
          <FontAwesomeIcon icon={faArrowRight} className={styles.arrow} />
        </div>
      </div>
    </section>
  );
}
