import { Routes, Route } from 'react-router-dom';
import { LandingPage } from '../../pages/LandingPage';
import { RentRoomPage } from '../../pages/RentRoomPage';
import { RentVenuePage } from '../../pages/RentVenuePage';
import { ContactPage } from '../../pages/ContactPage';
import { AboutUsPage } from '../../pages/AboutUsPage';
import { GalleryPage } from '../../pages/GalleryPage';
/**
 * component that creates the react routes that is used to navigate the site
 */
export function MyRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage></LandingPage>}></Route>
      <Route path="/galleri" element={<GalleryPage></GalleryPage>}></Route>
      <Route path="/lokaler" element={<RentVenuePage></RentVenuePage>}></Route>
      <Route
        path="/overnattinger"
        element={<RentRoomPage></RentRoomPage>}
      ></Route>
      <Route path="/kontaktOss" element={<ContactPage></ContactPage>}></Route>
      <Route path="/omOss" element={<AboutUsPage></AboutUsPage>}></Route>
    </Routes>
  );
}
