import { Helmet, HelmetProvider } from 'react-helmet-async';
import { RentVenueContactSection } from '../../components/RentVenueContactSection';
import { VenueImageAndTextSection } from '../../components/VenuePageImageAndText';
import { bigVenueText, cateringText, smallVenueText } from '../../js/venueText';
import styles from './rentVenuePage.module.css';
/**
 * component that creates the rent a venue page
 */
export function RentVenuePage() {
  return (
    <main>
      <HelmetProvider>
        <Helmet>
          <title>VærksHuset | Utleie av lokaler</title>
          <meta
            name="description"
            content="Lei våre flotte lokaler på Kleivene i Froland for ditt neste arrangement.
             Perfekt for møter, konferanser, selskaper, minnestunder, konfirmasjoner og andre begivenheter. flotte fasiliteter og naturskjønne omgivelser."
          />
          <meta
            name="keywords"
            content="lokaleutleie, Kleivene, Froland,Agder, møter, konfirmasjoner, minnestunder, bryllup, konferanser, selskaper, arrangementer, billig, moderne fasiliteter, naturskjønne omgivelser, eventlokaler, leie lokaler, begivenheter, konferanserom, selskapslokaler, booking, utleie"
          />
        </Helmet>
      </HelmetProvider>
      <h1 className={styles.h1}>Utleie av lokaler</h1>
      <p className={styles.introText}>
        Hvis du er på utkikk etter det perfekte stedet for ditt neste
        arrangement, har vi to flotte lokaler tilgjengelige som kan tilpasses
        dine behov. Lokalene kan brukes til både selskaper, konfirmasjoner,
        bryllup , minnestunder og mer. Servering kan ordnes etter ønske.
      </p>
      <VenueImageAndTextSection
        image="./img/VenueImg/lokaler.jpg"
        alt="bilde av lokalet"
        text={bigVenueText}
        h2="Stort lokale"
        reversed="false"
      ></VenueImageAndTextSection>
      <VenueImageAndTextSection
        image="./img/VenueImg/liteLokalet.jpg"
        alt="bilde av lokalet"
        text={smallVenueText}
        h2="Lite lokale"
        reversed="true"
      ></VenueImageAndTextSection>
      <VenueImageAndTextSection
        image="./img/VenueImg/catering.jpg"
        alt="bilde av catering mat"
        text={cateringText}
        h2="Servering"
        reversed="false"
      ></VenueImageAndTextSection>
      <RentVenueContactSection></RentVenueContactSection>
    </main>
  );
}
