import { Footer } from '../Footer';
import { Header } from '../Header';
import { MyRoutes } from '../Routes';
import { ScrollToTop } from '../ScrollToTop';
/**
 * component that creates the layout used on each page
 */
export function Layout() {
  return (
    <div>
      <Header></Header>
      <MyRoutes></MyRoutes>
      <ScrollToTop></ScrollToTop>
      <Footer></Footer>
    </div>
  );
}
