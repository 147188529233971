import { AboutUsContactInfo } from '../AboutUsContactSection';
import styles from './aboutUs.module.css';
/**
 * component that creates the about us section, this section is information about the business.
 */
export function AboutUsSection() {
  return (
    <section className={styles.AboutUsSection}>
      <h1>Om oss</h1>
      <div className={styles.aboutUsText}>
        <h2>Velkommen til Værkshuset!</h2>
        <p>
          Værkshuset åpnet dørene for første gang 4. juli 2024. Vår visjon er å
          være et levende samlingspunkt for lokalbefolkningen og besøkende, og
          vi er stolte av å kunne tilby overnattinger og tjenester som skaper
          minnerike opplevelser. I tillegg til overnatting tilbyr vi også utleie
          av lokaler som kan tilpasses dine behov, enten det er til møter,
          konferanser, minnestunder, private fester eller andre spesielle
          anledninger. Vi vil bidra til et levende og inkluderende lokalsamfunn,
          derfor planlegger vi en rekke kulturelle arrangementer for både store
          å små.
        </p>
        <p>
          Vi ser frem til å ønske deg velkommen til Værkshuset, enten du kommer
          for å overnatte, delta på et arrangement eller bare vil utforske hva
          vi har å tilby.
        </p>
        <div className={styles.whoAreWeSection}>
          <h3>Hvem er vi</h3>
          <p>
            Værkshuset eies og driftes av Olav Rosef, Rune Osmundsen og Cathrine
            Osmundsen
          </p>
          <div className={styles.imgCon}>
            <img
              src="/img/ansatte.jpg"
              alt="bilde av Rune Osmundsen og Cathrine Osmundsen"
            />
          </div>
        </div>
      </div>
      <AboutUsContactInfo></AboutUsContactInfo>
    </section>
  );
}
